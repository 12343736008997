import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";

// action types
export const SERIES_LIST = "series_list";
export const SERIES_SIMPLE_LIST = "series_simple_list";
export const CREATE_SERIES = "create_series";
export const UPDATE_SERIES = "update_series";
export const DELETE_SERIES = "delete_series";
export const CLEAR_SERIES_ERRORS = "clearSeriesErrors";

// mutation types
export const SET_SERIES_ERRORS = "setSeriesError";
export const REMOVE_SERIES_ERRORS = "removeSeriesErrors";

const state = {
  errors: null
};

const actions = {
  [SERIES_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable("api/series?" + payload.q + payload.columns.join(""))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_SERIES_ERRORS, response.errors);
        });
    });
  },
  [SERIES_SIMPLE_LIST]() {
    return new Promise(resolve => {
      ApiService.get("api/seriessimple").then(({ data }) => {
        if (data._metadata.outcomeCode === 0) {
          resolve(data);
        } else {
          this.dispatch(SET_SNACK, {
            clr: "error",
            msg: data._metadata.message
          });
        }
      });
    });
  },
  [CREATE_SERIES](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/createseries", payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_SERIES_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_SERIES_ERRORS, response.errors);
        });
    });
  },
  [UPDATE_SERIES](context, payload) {
    return new Promise(resolve => {
      return ApiService.update("api/updateseries", payload.slug, payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_SERIES_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_SERIES_ERRORS, response.errors);
        });
    });
  },
  [DELETE_SERIES](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/deleteseries", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_SERIES_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_SERIES_ERRORS, response.errors);
        });
    });
  },
  [CLEAR_SERIES_ERRORS](context) {
    context.commit(REMOVE_SERIES_ERRORS);
  }
};

const mutations = {
  [SET_SERIES_ERRORS](state, error) {
    state.errors = error;
  },
  [REMOVE_SERIES_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};
