import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import users from "./users.module";
import uploads from "./uploads.module";
import roles from "./roles.module";
import permissions from "./permissions.module";
import series from "./series.module";
import authors from "./authors.module";
import categories from "./categories.module";
import filters from "./filters.module";
import faqs from "./faqs.module";
import inquiries from "./inquiries.module";
import filtervalues from "./filtervalues.module";
import books from "./books.module";
import menus from "./menus.module";
import pages from "./pages.module";
import redirects from "./redirects.module";
import sitesettings from "./sitesettings.module";
import adminsettings from "./adminsettings.module";
import homepagesettings from "./homepagesettings.module";
import banners from "./banners.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import snackbar from "./snackbar.module";
import confirmation from "./confirmation.module";
import SellSheets from "./sellSheets.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    users,
    uploads,
    roles,
    permissions,
    series,
    authors,
    categories,
    filters,
    faqs,
    inquiries,
    filtervalues,
    books,
    menus,
    pages,
    redirects,
    sitesettings,
    adminsettings,
    homepagesettings,
    banners,
    htmlClass,
    config,
    breadcrumbs,
    snackbar,
    confirmation,
    SellSheets
  }
});
