import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";

// action types
export const UPLOAD_IMAGE = "upload_image";
export const DELETE_UPLOADED_IMAGE = "delete_uploaded_image";
export const CLEAR_UPLOAD_ERRORS = "clearUploadErrors";

// mutation types
export const SET_UPLOAD_ERRORS = "setUploadError";
export const REMOVE_UPLOAD_ERRORS = "removeUploadErrors";

const state = {
  errors: null
};

const actions = {
  [UPLOAD_IMAGE](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/uploadimage", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_UPLOAD_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_UPLOAD_ERRORS, response.errors);
        });
    });
  },
  [DELETE_UPLOADED_IMAGE](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/deleteuploadedimage", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_UPLOAD_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_UPLOAD_ERRORS, response.errors);
        });
    });
  },
  [CLEAR_UPLOAD_ERRORS](context) {
    context.commit(REMOVE_UPLOAD_ERRORS);
  }
};

const mutations = {
  [SET_UPLOAD_ERRORS](state, error) {
    state.errors = error;
  },
  [REMOVE_UPLOAD_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};
