import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";

// action types
export const CATEGORIES_LIST = "categories_list";
export const CATEGORIES_SIMPLE_LIST = "categories_simple_list";
export const CREATE_CATEGORY = "create_category";
export const UPDATE_CATEGORY = "update_category";
export const DELETE_CATEGORY = "delete_category";
export const GET_CATEGORY_DETAILS = "get_category_details";
export const GET_CATEGORY_PERMISSIONS = "get_category_permissions";
export const GET_CATEGORY_PERMISSIONS_EXCEPT =
  "get_category_permissions_except";
export const CLEAR_CATEGORY_ERRORS = "clearCategoryErrors";

// mutation types
export const SET_CATEGORY_ERROR = "setCategoryError";
export const REMOVE_CATEGORY_ERRORS = "removeCategoryErrors";

const state = {
  errors: null
};

const actions = {
  [CATEGORIES_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable(
        "api/categories?" + payload.q + payload.columns.join("")
      )
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_CATEGORY_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_CATEGORY_ERROR, response.errors);
        });
    });
  },
  [CATEGORIES_SIMPLE_LIST]() {
    return new Promise(resolve => {
      ApiService.get("api/categoriessimple").then(({ data }) => {
        if (data._metadata.outcomeCode === 0) {
          resolve(data);
        } else {
          this.dispatch(SET_SNACK, {
            clr: "error",
            msg: data._metadata.message
          });
        }
      });
    });
  },
  [CREATE_CATEGORY](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/createcategory", payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_CATEGORY_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_CATEGORY_ERROR, response.errors);
        });
    });
  },
  [UPDATE_CATEGORY](context, payload) {
    return new Promise(resolve => {
      return ApiService.update("api/updatecategory", payload.slug, payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_CATEGORY_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_CATEGORY_ERROR, response.errors);
        });
    });
  },
  [DELETE_CATEGORY](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/deletecategory", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_CATEGORY_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_CATEGORY_ERROR, response.errors);
        });
    });
  },
  [GET_CATEGORY_DETAILS](context, id) {
    return new Promise(resolve => {
      ApiService.get("api/getcategorydetail", id)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_CATEGORY_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_CATEGORY_ERROR, response.errors);
        });
    });
  },
  [GET_CATEGORY_PERMISSIONS](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable(
        "api/getcategorypermissions/" +
          payload.slug +
          "?" +
          payload.q +
          payload.columns.join("")
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_CATEGORY_ERROR, response.errors);
        });
    });
  },
  [GET_CATEGORY_PERMISSIONS_EXCEPT](context, payload) {
    return new Promise(resolve => {
      ApiService.get("api/getcategorypermissionsexcept", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_CATEGORY_ERROR, response.errors);
        });
    });
  },
  [CLEAR_CATEGORY_ERRORS](context) {
    context.commit(REMOVE_CATEGORY_ERRORS);
  }
};

const mutations = {
  [SET_CATEGORY_ERROR](state, error) {
    state.errors = error;
  },
  [REMOVE_CATEGORY_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};
