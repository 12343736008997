import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";

// action types
export const AUTHORS_LIST = "authors_list";
export const AUTHORS_SIMPLE_LIST = "authors_simple_list";
export const CREATE_AUTHOR = "create_author";
export const UPDATE_AUTHOR = "update_author";
export const DELETE_AUTHOR = "delete_author";
export const GET_AUTHOR_DETAILS = "get_author_details";
export const CLEAR_AUTHOR_ERRORS = "clearAuthorErrors";

// mutation types
export const SET_AUTHOR_ERRORS = "setAuthorError";
export const REMOVE_AUTHOR_ERRORS = "removeAuthorErrors";

const state = {
  errors: null
};

const actions = {
  [AUTHORS_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable(
        "api/authors?" + payload.q + payload.columns.join("")
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_AUTHOR_ERRORS, response.errors);
        });
    });
  },
  [AUTHORS_SIMPLE_LIST]() {
    return new Promise(resolve => {
      ApiService.get("api/authorssimple").then(({ data }) => {
        if (data._metadata.outcomeCode === 0) {
          resolve(data);
        } else {
          this.dispatch(SET_SNACK, {
            clr: "error",
            msg: data._metadata.message
          });
        }
      });
    });
  },
  [GET_AUTHOR_DETAILS](context, id) {
    return new Promise(resolve => {
      ApiService.get("api/getauthordetail", id)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_AUTHOR_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_AUTHOR_ERRORS, response.errors);
        });
    });
  },
  [CREATE_AUTHOR](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/createauthor", payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_AUTHOR_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_AUTHOR_ERRORS, response.errors);
        });
    });
  },
  [UPDATE_AUTHOR](context, payload) {
    return new Promise(resolve => {
      return ApiService.update("api/updateauthor", payload.slug, payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_AUTHOR_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_AUTHOR_ERRORS, response.errors);
        });
    });
  },
  [DELETE_AUTHOR](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/deleteauthor", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_AUTHOR_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_AUTHOR_ERRORS, response.errors);
        });
    });
  },
  [CLEAR_AUTHOR_ERRORS](context) {
    context.commit(REMOVE_AUTHOR_ERRORS);
  }
};

const mutations = {
  [SET_AUTHOR_ERRORS](state, error) {
    state.errors = error;
  },
  [REMOVE_AUTHOR_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};
