import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";

// action types
export const SITESETTINGS_LIST = "sitesettings_list";
export const UPDATE_SITESETTING = "update_sitesetting";
export const GET_SITESETTING_DETAILS = "get_sitesetting_details";
export const CLEAR_SITESETTING_ERRORS = "clearSiteSettingErrors";

// mutation types
export const SET_SITESETTING_ERRORS = "setSiteSettingError";
export const REMOVE_SITESETTING_ERRORS = "removeSiteSettingErrors";

const state = {
  errors: null
};

const actions = {
  [SITESETTINGS_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable(
        "api/sitesettings?" + payload.q + payload.columns.join("")
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_SITESETTING_ERRORS, response.errors);
        });
    });
  },
  [GET_SITESETTING_DETAILS](context, id) {
    return new Promise(resolve => {
      ApiService.get("api/getsitesettingdetail", id)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_SITESETTING_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_SITESETTING_ERRORS, response.errors);
        });
    });
  },
  [UPDATE_SITESETTING](context, payload) {
    return new Promise(resolve => {
      return ApiService.update(
        "api/updatesitesetting",
        payload.slug,
        payload.data
      )
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_SITESETTING_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_SITESETTING_ERRORS, response.errors);
        });
    });
  },
  [CLEAR_SITESETTING_ERRORS](context) {
    context.commit(REMOVE_SITESETTING_ERRORS);
  }
};

const mutations = {
  [SET_SITESETTING_ERRORS](state, error) {
    state.errors = error;
  },
  [REMOVE_SITESETTING_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};
