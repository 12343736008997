import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import moment from "moment";
import store from "@/core/services/store";
import AuthService from "@/core/services/auth.service";
import ApiService from "@/core/services/api.service";
import auth from "@/core/services/store/auth.module";
import { LOGOUT } from "@/core/services/store/auth.module";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import Editor from "@tinymce/tinymce-vue";

Vue.config.productionTip = false;
Vue.prototype.moment = moment;
// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";

window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";

window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import { getToken } from "./core/services/jwt.service";
import { GET_ADMINSETTINGS } from "./core/services/store/adminsettings.module";
// API service init
Vue.component("editor", Editor);
ApiService.init();

Vue.mixin({
  data() {
    return {
      tinyKey: "t4bnjd6x954v4zrvp0eh7k5wxson31bo0gaopxemrbxsu5a0"
    };
  },
  methods: {
    hasRole(role) {
      if (store.state.auth.roles.length !== 0) {
        return store.state.auth.roles.some(spec => {
          return spec === role ? true : false;
        });
      }
    },

    hasPermission(permission) {
      if (store.state.auth.permissions.length !== 0) {
        return store.state.auth.permissions.some(spec => {
          return spec === permission ? true : false;
        });
      }
    },

    isNumber(event) {
      if (!/^[0-9]+$/.test(event.key) || event.key === ".")
        return event.preventDefault();
    }
  }
});
router.beforeEach((to, from, next) => {
  if (to.name === "login") {
    store.dispatch(GET_ADMINSETTINGS);
  }
  if (!getToken() && auth.state.isAuthenticated) {
    store.dispatch(LOGOUT).then(() => router.push("login"));
    return false;
  }

  Promise.all([
    store.dispatch(VERIFY_AUTH, to.name).then(data => {
      if (data && data._metadata.outcomeCode === 0) {
        if (to.meta.auth) {
          if (AuthService.hasPermission(to.meta.permission)) {
            next();
          } else {
            router.push("login");
          }
        }
      }
      // console.log(AuthService.hasPermission(to.meta.permission));
    })
  ]).then(next);
  // if (to.meta.auth) {
  //     if (AuthService.hasRole(to.meta.role) && AuthService.hasPermission(to.meta.permission)) {
  //         Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  //     } else {
  //         Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  //         window.location = '#/dashboard'
  //     }
  // } else {
  //     Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  // }

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export const bus = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
