import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";

// action types
export const FILTERS_LIST = "filters_list";
export const FILTERS_SIMPLE_LIST = "filters_simple_list";
export const CREATE_FILTER = "create_filter";
export const UPDATE_FILTER = "update_filter";
export const DELETE_FILTER = "delete_filter";
export const GET_FILTER_DETAILS = "get_filter_details";
export const CLEAR_FILTER_ERRORS = "clearFilterErrors";

// mutation types
export const SET_FILTER_ERROR = "setFilterError";
export const REMOVE_FILTER_ERRORS = "removeFilterErrors";

const state = {
  errors: null
};

const actions = {
  [FILTERS_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable(
        "api/filters?" + payload.q + payload.columns.join("")
      )
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_FILTER_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_FILTER_ERROR, response.errors);
        });
    });
  },
  [FILTERS_SIMPLE_LIST]() {
    return new Promise(resolve => {
      ApiService.get("api/filterssimple").then(({ data }) => {
        if (data._metadata.outcomeCode === 0) {
          resolve(data);
        } else {
          this.dispatch(SET_SNACK, {
            clr: "error",
            msg: data._metadata.message
          });
        }
      });
    });
  },
  [CREATE_FILTER](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/createfilter", payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_FILTER_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_FILTER_ERROR, response.errors);
        });
    });
  },
  [UPDATE_FILTER](context, payload) {
    return new Promise(resolve => {
      return ApiService.update("api/updatefilter", payload.slug, payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_FILTER_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_FILTER_ERROR, response.errors);
        });
    });
  },
  [DELETE_FILTER](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/deletefilter", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_FILTER_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_FILTER_ERROR, response.errors);
        });
    });
  },
  [GET_FILTER_DETAILS](context, id) {
    return new Promise(resolve => {
      ApiService.get("api/getfilterdetail", id)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_FILTER_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_FILTER_ERROR, response.errors);
        });
    });
  },
  [CLEAR_FILTER_ERRORS](context) {
    context.commit(REMOVE_FILTER_ERRORS);
  }
};

const mutations = {
  [SET_FILTER_ERROR](state, error) {
    state.errors = error;
  },
  [REMOVE_FILTER_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};
