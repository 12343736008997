import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";

// action types
export const BOOKS_LIST = "books_list";
export const BOOKS_SIMPLE_LIST = "books_simple_list";
export const CREATE_BOOK = "create_book";
export const UPDATE_BOOK = "update_book";
export const UPDATE_BOOK_PRICE = "update_book_price";
export const DELETE_BOOK = "delete_book";
export const GET_BOOK_DETAILS = "get_book_details";
export const CLEAR_BOOK_ERRORS = "clearBookErrors";

// mutation types
export const SET_BOOK_ERRORS = "setBookError";
export const REMOVE_BOOK_ERRORS = "removeBookErrors";

const state = {
  errors: null
};

const actions = {
  [BOOKS_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable("api/books?" + payload.q + payload.columns.join(""))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_BOOK_ERRORS, response.errors);
        });
    });
  },
  [BOOKS_SIMPLE_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable("api/bookssimple?search=" + payload.search + '&q=' + payload.q).then(({ data }) => {
        if (data._metadata.outcomeCode === 0) {
          resolve(data);
        } else {
          this.dispatch(SET_SNACK, {
            clr: "error",
            msg: data._metadata.message
          });
          context.commit(SET_BOOK_ERRORS, data.errors);
        }
      });
    });
  },
  [GET_BOOK_DETAILS](context, id) {
    return new Promise(resolve => {
      ApiService.get("api/getbookdetail", id)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_BOOK_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_BOOK_ERRORS, response.errors);
        });
    });
  },
  [CREATE_BOOK](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/createbook", payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_BOOK_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_BOOK_ERRORS, response.errors);
        });
    });
  },
  [UPDATE_BOOK](context, payload) {
    return new Promise(resolve => {
      return ApiService.update("api/updatebook", payload.slug, payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_BOOK_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_BOOK_ERRORS, response.errors);
        });
    });
  },
  [UPDATE_BOOK_PRICE](context, payload) {
    return new Promise(resolve => {
      return ApiService.update("api/updatebook-price", payload.slug, payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_BOOK_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_BOOK_ERRORS, response.errors);
        });
    });
  },
  [DELETE_BOOK](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/deletebook", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_BOOK_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_BOOK_ERRORS, response.errors);
        });
    });
  },
  [CLEAR_BOOK_ERRORS](context) {
    context.commit(REMOVE_BOOK_ERRORS);
  }
};

const mutations = {
  [SET_BOOK_ERRORS](state, error) {
    state.errors = error;
  },
  [REMOVE_BOOK_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};
