import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";

// action types
export const REDIRECTS_LIST = "redirects_list";
export const REDIRECTS_SIMPLE_LIST = "redirects_simple_list";
export const CREATE_REDIRECT = "create_redirect";
export const UPDATE_REDIRECT = "update_redirect";
export const DELETE_REDIRECT = "delete_redirect";
export const GET_REDIRECT_DETAILS = "get_redirect_details";
export const CLEAR_REDIRECT_ERRORS = "clearRedirectErrors";

// mutation types
export const SET_REDIRECT_ERROR = "setRedirectError";
export const REMOVE_REDIRECT_ERRORS = "removeRedirectErrors";

const state = {
  errors: null
};

const actions = {
  [REDIRECTS_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable(
        "api/redirects?" + payload.q + payload.columns.join("")
      )
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_REDIRECT_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_REDIRECT_ERROR, response.errors);
        });
    });
  },
  [REDIRECTS_SIMPLE_LIST]() {
    return new Promise(resolve => {
      ApiService.get("api/redirectssimple").then(({ data }) => {
        if (data._metadata.outcomeCode === 0) {
          resolve(data);
        } else {
          this.dispatch(SET_SNACK, {
            clr: "error",
            msg: data._metadata.message
          });
        }
      });
    });
  },
  [CREATE_REDIRECT](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/createredirect", payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_REDIRECT_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_REDIRECT_ERROR, response.errors);
        });
    });
  },
  [UPDATE_REDIRECT](context, payload) {
    return new Promise(resolve => {
      return ApiService.update("api/updateredirect", payload.slug, payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_REDIRECT_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_REDIRECT_ERROR, response.errors);
        });
    });
  },
  [DELETE_REDIRECT](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/deleteredirect", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_REDIRECT_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_REDIRECT_ERROR, response.errors);
        });
    });
  },
  [GET_REDIRECT_DETAILS](context, id) {
    return new Promise(resolve => {
      ApiService.get("api/getredirectdetail", id)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_REDIRECT_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_REDIRECT_ERROR, response.errors);
        });
    });
  },
  [CLEAR_REDIRECT_ERRORS](context) {
    context.commit(REMOVE_REDIRECT_ERRORS);
  }
};

const mutations = {
  [SET_REDIRECT_ERROR](state, error) {
    state.errors = error;
  },
  [REMOVE_REDIRECT_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};
