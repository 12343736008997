import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";

// action types
export const FAQS_LIST = "faqs_list";
export const FAQS_SIMPLE_LIST = "faqs_simple_list";
export const CREATE_FAQ = "create_faq";
export const UPDATE_FAQ = "update_faq";
export const DELETE_FAQ = "delete_faq";
export const GET_FAQ_DETAILS = "get_faq_details";
export const CLEAR_FAQ_ERRORS = "clearFaqErrors";

// mutation types
export const SET_FAQ_ERROR = "setFaqError";
export const REMOVE_FAQ_ERRORS = "removeFaqErrors";

const state = {
  errors: null
};

const actions = {
  [FAQS_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable("api/faqs?" + payload.q + payload.columns.join(""))
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_FAQ_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_FAQ_ERROR, response.errors);
        });
    });
  },
  [FAQS_SIMPLE_LIST]() {
    return new Promise(resolve => {
      ApiService.get("api/faqssimple").then(({ data }) => {
        if (data._metadata.outcomeCode === 0) {
          resolve(data);
        } else {
          this.dispatch(SET_SNACK, {
            clr: "error",
            msg: data._metadata.message
          });
        }
      });
    });
  },
  [CREATE_FAQ](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/createfaq", payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_FAQ_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_FAQ_ERROR, response.errors);
        });
    });
  },
  [UPDATE_FAQ](context, payload) {
    return new Promise(resolve => {
      return ApiService.update("api/updatefaq", payload.slug, payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_FAQ_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_FAQ_ERROR, response.errors);
        });
    });
  },
  [DELETE_FAQ](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/deletefaq", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_FAQ_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_FAQ_ERROR, response.errors);
        });
    });
  },
  [GET_FAQ_DETAILS](context, id) {
    return new Promise(resolve => {
      ApiService.get("api/getfaqdetail", id)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_FAQ_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_FAQ_ERROR, response.errors);
        });
    });
  },
  [CLEAR_FAQ_ERRORS](context) {
    context.commit(REMOVE_FAQ_ERRORS);
  }
};

const mutations = {
  [SET_FAQ_ERROR](state, error) {
    state.errors = error;
  },
  [REMOVE_FAQ_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};
