import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";

// action types
export const PAGES_LIST = "pages_list";
export const PAGES_SIMPLE_LIST = "pages_simple_list";
export const CREATE_PAGE = "create_page";
export const UPDATE_PAGE = "update_page";
export const DELETE_PAGE = "delete_page";
export const CREATE_EMAIL_TEMPLATE_PAGE = "create_email_template_page";
export const GET_PAGE_DETAILS = "get_page_details";
export const CLEAR_PAGE_ERRORS = "clearPageErrors";
export const FETCH_EMAIL_TEMPLATE = "fetch_email_template";
export const UPDATE_EMAIL_TEMPLATE = "update_email_template";

// mutation types
export const SET_PAGE_ERRORS = "setPageError";
export const REMOVE_PAGE_ERRORS = "removePageErrors";

const state = {
  errors: null
};

const actions = {
  [PAGES_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable("api/pages?" + payload.q + payload.columns.join(""))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_PAGE_ERRORS, response.errors);
        });
    });
  },
  [PAGES_SIMPLE_LIST]() {
    return new Promise(resolve => {
      ApiService.get("api/pagessimple").then(({ data }) => {
        if (data._metadata.outcomeCode === 0) {
          resolve(data);
        } else {
          this.dispatch(SET_SNACK, {
            clr: "error",
            msg: data._metadata.message
          });
        }
      });
    });
  },
  [GET_PAGE_DETAILS](context, id) {
    return new Promise(resolve => {
      ApiService.get("api/getpagedetail", id)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_PAGE_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_PAGE_ERRORS, response.errors);
        });
    });
  },
  [CREATE_PAGE](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/createpage", payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_PAGE_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_PAGE_ERRORS, response.errors);
        });
    });
  },
  [UPDATE_PAGE](context, payload) {
    return new Promise(resolve => {
      return ApiService.update("api/updatepage", payload.slug, payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_PAGE_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_PAGE_ERRORS, response.errors);
        });
    });
  },
  [UPDATE_EMAIL_TEMPLATE](context, payload) {
    return new Promise(resolve => {
      return ApiService.update("api/updateemailtemplatepage", payload.id, payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_PAGE_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_PAGE_ERRORS, response.errors);
        });
    });
  },
  [DELETE_PAGE](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/deletepage", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_PAGE_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_PAGE_ERRORS, response.errors);
        });
    });
  },
  [CREATE_EMAIL_TEMPLATE_PAGE](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/createemailtemplatepage", payload.data)
        .then(({ data }) => {
          console.log(data)
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_PAGE_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_PAGE_ERRORS, response.errors);
        });
    });
  },
  [CLEAR_PAGE_ERRORS](context) {
    context.commit(REMOVE_PAGE_ERRORS);
  },
  [FETCH_EMAIL_TEMPLATE]() {
    return new Promise(resolve => {
      ApiService.get("api/fetchemailtemplatepage").then(({ data }) => {
        if (data._metadata.outcomeCode === 0) {
          resolve(data);
        } else {
          this.dispatch(SET_SNACK, {
            clr: "error",
            msg: data._metadata.message
          });
        }
      });
    });
  },

};

const mutations = {
  [SET_PAGE_ERRORS](state, error) {
    state.errors = error;
  },
  [REMOVE_PAGE_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};
