import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";

// action types
export const FILTERVALUES_LIST = "filtervalues_list";
export const FILTERVALUES_SIMPLE_LIST = "filtervalues_simple_list";
export const BOOKLENGTHS_FILTERVALUES_LIST =
  "booklengths_filtervalues_simple_list";
export const IMPRINTS_FILTERVALUES_LIST = "imprints_filtervalues_simple_list";
export const GENRES_FILTERVALUES_LIST = "genres_filtervalues_simple_list";
export const TROPES_FILTERVALUES_LIST = "tropes_filtervalues_simple_list";
export const CHARACTERAGE_FILTERVALUES_LIST =
  "characterage_filtervalues_simple_list";
export const CREATE_FILTERVALUE = "create_filtervalue";
export const UPDATE_FILTERVALUE = "update_filtervalue";
export const DELETE_FILTERVALUE = "delete_filtervalue";
export const GET_FILTERVALUE_DETAILS = "get_filtervalue_details";
export const CLEAR_FILTERVALUE_ERRORS = "clearFilterValueErrors";

// mutation types
export const SET_FILTERVALUE_ERROR = "setFilterValueError";
export const REMOVE_FILTERVALUE_ERRORS = "removeFilterValueErrors";

const state = {
  errors: null
};

const actions = {
  [FILTERVALUES_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable(
        "api/filtervalues?" + payload.q + payload.columns.join("")
      )
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_FILTERVALUE_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_FILTERVALUE_ERROR, response.errors);
        });
    });
  },
  [FILTERVALUES_SIMPLE_LIST]() {
    return new Promise(resolve => {
      ApiService.get("api/filtervaluessimple").then(({ data }) => {
        if (data._metadata.outcomeCode === 0) {
          resolve(data);
        } else {
          this.dispatch(SET_SNACK, {
            clr: "error",
            msg: data._metadata.message
          });
        }
      });
    });
  },
  [BOOKLENGTHS_FILTERVALUES_LIST]() {
    return new Promise(resolve => {
      ApiService.get("api/booklengthsfiltervalues").then(({ data }) => {
        if (data._metadata.outcomeCode === 0) {
          resolve(data);
        } else {
          this.dispatch(SET_SNACK, {
            clr: "error",
            msg: data._metadata.message
          });
        }
      });
    });
  },
  [IMPRINTS_FILTERVALUES_LIST]() {
    return new Promise(resolve => {
      ApiService.get("api/imprintsfiltervalues").then(({ data }) => {
        if (data._metadata.outcomeCode === 0) {
          resolve(data);
        } else {
          this.dispatch(SET_SNACK, {
            clr: "error",
            msg: data._metadata.message
          });
        }
      });
    });
  },
  [GENRES_FILTERVALUES_LIST]() {
    return new Promise(resolve => {
      ApiService.get("api/genresfiltervalues").then(({ data }) => {
        if (data._metadata.outcomeCode === 0) {
          resolve(data);
        } else {
          this.dispatch(SET_SNACK, {
            clr: "error",
            msg: data._metadata.message
          });
        }
      });
    });
  },
  [TROPES_FILTERVALUES_LIST]() {
    return new Promise(resolve => {
      ApiService.get("api/tropesfiltervalues").then(({ data }) => {
        if (data._metadata.outcomeCode === 0) {
          resolve(data);
        } else {
          this.dispatch(SET_SNACK, {
            clr: "error",
            msg: data._metadata.message
          });
        }
      });
    });
  },
  [CHARACTERAGE_FILTERVALUES_LIST]() {
    return new Promise(resolve => {
      ApiService.get("api/characteragefiltervalues").then(({ data }) => {
        if (data._metadata.outcomeCode === 0) {
          resolve(data);
        } else {
          this.dispatch(SET_SNACK, {
            clr: "error",
            msg: data._metadata.message
          });
        }
      });
    });
  },
  [CREATE_FILTERVALUE](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/createfiltervalue", payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_FILTERVALUE_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_FILTERVALUE_ERROR, response.errors);
        });
    });
  },
  [UPDATE_FILTERVALUE](context, payload) {
    return new Promise(resolve => {
      return ApiService.update(
        "api/updatefiltervalue",
        payload.slug,
        payload.data
      )
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_FILTERVALUE_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_FILTERVALUE_ERROR, response.errors);
        });
    });
  },
  [DELETE_FILTERVALUE](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/deletefiltervalue", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_FILTERVALUE_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_FILTERVALUE_ERROR, response.errors);
        });
    });
  },
  [GET_FILTERVALUE_DETAILS](context, id) {
    return new Promise(resolve => {
      ApiService.get("api/getfiltervaluedetail", id)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_FILTERVALUE_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_FILTERVALUE_ERROR, response.errors);
        });
    });
  },
  [CLEAR_FILTERVALUE_ERRORS](context) {
    context.commit(REMOVE_FILTERVALUE_ERRORS);
  }
};

const mutations = {
  [SET_FILTERVALUE_ERROR](state, error) {
    state.errors = error;
  },
  [REMOVE_FILTERVALUE_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};
