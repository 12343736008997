import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";

// action types
export const SELL_SHEETS_LIST = "SELL_SHEETS_LIST";
export const CREATE_SELL_SHEET = "CREATE_SELL_SHEET";
export const UPDATE_SELL_SHEET = "UPDATE_SELL_SHEET";
export const DELETE_SELL_SHEET = "DELETE_SELL_SHEET";
export const GET_SELL_SHEET_DETAILS = "GET_SELL_SHEET_DETAILS";
export const CLEAR_SELL_SHEET_ERRORS = "CLEAR_SELL_SHEET_ERRORS";

// mutation types
export const SET_SELL_SHEET_ERRORS = "SET_SELL_SHEET_ERRORS";
export const REMOVE_SELL_SHEET_ERRORS = "REMOVE_SELL_SHEET_ERRORS";

const state = {
  errors: null
};

const actions = {
  [SELL_SHEETS_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable(
        "api/sellsheets?" + payload.q + payload.columns.join("")
      )
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_SELL_SHEET_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_SELL_SHEET_ERRORS, response.errors);
        });
    });
  },
  [GET_SELL_SHEET_DETAILS](context, id) {
    return new Promise(resolve => {
      ApiService.get("api/getsellsheetdetail", id)
          .then(({ data }) => {
            if (data._metadata.outcomeCode === 0) {
              resolve(data);
            } else {
              this.dispatch(SET_SNACK, {
                clr: "error",
                msg: data._metadata.message
              });
              context.commit(SET_SELL_SHEET_ERRORS, data.errors);
            }
          })
          .catch(({ response }) => {
            context.commit(SET_SELL_SHEET_ERRORS, response.errors);
          });
    });
  },
  [CREATE_SELL_SHEET](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/createsellsheet", payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_SELL_SHEET_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_SELL_SHEET_ERRORS, response.errors);
        });
    });
  },
  [UPDATE_SELL_SHEET](context, payload) {
    return new Promise(resolve => {
      return ApiService.update("api/updatesellsheet", payload.slug, payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_SELL_SHEET_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_SELL_SHEET_ERRORS, response.errors);
        });
    });
  },
  [DELETE_SELL_SHEET](context, payload) {
    var self = this;
    return new Promise(resolve => {
      return ApiService.post("api/deletesellsheet", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            self.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            self.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_SELL_SHEET_ERRORS, data.errors);
          }
        })
        .catch(response => {
          context.commit(SET_SELL_SHEET_ERRORS, response.errors);
        });
    });
  },
  
  [CLEAR_SELL_SHEET_ERRORS](context) {
    context.commit(REMOVE_SELL_SHEET_ERRORS);
  }
};

const mutations = {
  [SET_SELL_SHEET_ERRORS](state, error) {
    state.errors = error;
  },
  [REMOVE_SELL_SHEET_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};
