import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";
import { SET_ADMIN_SETTINGS, VERIFY_AUTH } from "./auth.module";

// action types
export const GET_ADMINSETTINGS = "get_adminsettings";
export const ADMINSETTINGS_LIST = "adminsettings_list";
export const UPDATE_ADMINSETTING = "update_adminsetting";
export const GET_ADMINSETTING_DETAILS = "get_adminsetting_details";
export const CLEAR_ADMINSETTING_ERRORS = "clearAdminSettingErrors";

// mutation types
export const SET_ADMINSETTING_ERRORS = "setAdminSettingError";
export const REMOVE_ADMINSETTING_ERRORS = "removeAdminSettingErrors";

const state = {
  errors: null
};

const actions = {
  [GET_ADMINSETTINGS](context) {
    ApiService.get("api/v1/getadminsettings")
      .then(({ data }) => {
        if (data._metadata.outcomeCode === 0) {
          context.commit(SET_ADMIN_SETTINGS, data);
        }
      })
      .catch(({ response }) => {
        context.commit(SET_ADMINSETTING_ERRORS, response.errors);
      });
  },
  [ADMINSETTINGS_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable(
        "api/adminsettings?" + payload.q + payload.columns.join("")
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ADMINSETTING_ERRORS, response.errors);
        });
    });
  },
  [GET_ADMINSETTING_DETAILS](context, id) {
    return new Promise(resolve => {
      ApiService.get("api/getadminsettingdetail", id)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_ADMINSETTING_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ADMINSETTING_ERRORS, response.errors);
        });
    });
  },
  [UPDATE_ADMINSETTING](context, payload) {
    return new Promise(resolve => {
      return ApiService.update(
        "api/updateadminsetting",
        payload.slug,
        payload.data
      )
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            this.dispatch(VERIFY_AUTH);
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_ADMINSETTING_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ADMINSETTING_ERRORS, response.errors);
        });
    });
  },
  [CLEAR_ADMINSETTING_ERRORS](context) {
    context.commit(REMOVE_ADMINSETTING_ERRORS);
  }
};

const mutations = {
  [SET_ADMINSETTING_ERRORS](state, error) {
    state.errors = error;
  },
  [REMOVE_ADMINSETTING_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};
