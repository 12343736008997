import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";

// action types
export const HOMEPAGESETTINGS_LIST = "homepagesettings_list";
export const UPDATE_HOMEPAGESETTING = "update_homepagesetting";
export const GET_HOMEPAGESETTING_DETAILS = "get_homepagesetting_details";
export const CLEAR_HOMEPAGESETTING_ERRORS = "clearHomePageSettingErrors";

// mutation types
export const SET_HOMEPAGESETTING_ERRORS = "setHomePageSettingError";
export const REMOVE_HOMEPAGESETTING_ERRORS = "removeHomePageSettingErrors";

const state = {
  errors: null
};

const actions = {
  [HOMEPAGESETTINGS_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable(
        "api/homepagesettings?" + payload.q + payload.columns.join("")
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_HOMEPAGESETTING_ERRORS, response.errors);
        });
    });
  },
  [GET_HOMEPAGESETTING_DETAILS](context, id) {
    return new Promise(resolve => {
      ApiService.get("api/gethomepagesettingdetail", id)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_HOMEPAGESETTING_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_HOMEPAGESETTING_ERRORS, response.errors);
        });
    });
  },
  [UPDATE_HOMEPAGESETTING](context, payload) {
    return new Promise(resolve => {
      return ApiService.update(
        "api/updatehomepagesetting",
        payload.slug,
        payload.data
      )
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_HOMEPAGESETTING_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_HOMEPAGESETTING_ERRORS, response.errors);
        });
    });
  },
  [CLEAR_HOMEPAGESETTING_ERRORS](context) {
    context.commit(REMOVE_HOMEPAGESETTING_ERRORS);
  }
};

const mutations = {
  [SET_HOMEPAGESETTING_ERRORS](state, error) {
    state.errors = error;
  },
  [REMOVE_HOMEPAGESETTING_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};
