import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";

// action types
export const INQUIRIES_LIST = "INQUIRIES_LIST";
export const CLEAR_INQUIRIES_ERRORS = "CLEAR_INQUIRIES_ERRORS";

// mutation types
export const SET_INQUIRIES_ERROR = "SET_INQUIRIES_ERROR";
export const REMOVE_INQUIRIES_ERRORS = "REMOVE_INQUIRIES_ERRORS";

const state = {
  errors: null
};

const actions = {
  [INQUIRIES_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable("api/inquiries?" + payload.q + payload.columns.join(""))
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_INQUIRIES_ERROR, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_INQUIRIES_ERROR, response.errors);
        });
    });
  },
  [CLEAR_INQUIRIES_ERRORS](context) {
    context.commit(REMOVE_INQUIRIES_ERRORS);
  }
};

const mutations = {
  [SET_INQUIRIES_ERROR](state, error) {
    state.errors = error;
  },
  [REMOVE_INQUIRIES_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};
