import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";

// action types
export const USERS_LIST = "users_list";
export const CREATE_USER = "create_user";
export const UPDATE_USER = "update_user";
export const DELETE_USER = "delete_user";
export const GET_USER_DETAILS = "get_user_details";
export const GET_USER_ROLES = "get_user_roles";
export const CLEAR_USER_ERRORS = "clearUserErrors";

// mutation types
export const SET_USER_ERRORS = "setUserError";
export const REMOVE_USER_ERRORS = "removeUserErrors";

const state = {
  errors: null
};

const actions = {
  [USERS_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable("api/users?" + payload.q + payload.columns.join(""))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_USER_ERRORS, response.errors);
        });
    });
  },
  [GET_USER_DETAILS](context, id) {
    return new Promise(resolve => {
      ApiService.get("api/getuserdetail", id)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_USER_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_USER_ERRORS, response.errors);
        });
    });
  },
  [CREATE_USER](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/createuser", payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_USER_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_USER_ERRORS, response.errors);
        });
    });
  },
  [UPDATE_USER](context, payload) {
    return new Promise(resolve => {
      return ApiService.update("api/updateuser", payload.slug, payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_USER_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_USER_ERRORS, response.errors);
        });
    });
  },
  [DELETE_USER](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/deleteuser", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_USER_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_USER_ERRORS, response.errors);
        });
    });
  },
  [GET_USER_ROLES](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable(
        "api/getuserroles/" +
          payload.slug +
          "?" +
          payload.q +
          payload.columns.join("")
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_USER_ERRORS, response.errors);
        });
    });
  },
  [CLEAR_USER_ERRORS](context) {
    context.commit(REMOVE_USER_ERRORS);
  }
};

const mutations = {
  [SET_USER_ERRORS](state, error) {
    state.errors = error;
  },
  [REMOVE_USER_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};
